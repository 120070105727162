<template>
  <page-view :title="title">
    <a-card :bordered="false">
      <a-input
        style="width: 8%; margin-left: 8px;"
        placeholder="订单id"
        v-model="queryParam.id"
      />
      <a-input
        style="width: 8%; margin-left: 8px;"
        placeholder="订单号"
        v-model="queryParam.orderNo"
      />
      <a-input
        style="width: 8%; margin-left: 8px;"
        placeholder="姓名"
        v-model="queryParam.name"
      />
      <a-input
        style="width: 8%; margin-left: 8px;"
        placeholder="手机号"
        v-model="queryParam.mobile"
      />
      <a-select
        style="width: 8%; margin-left: 8px;"
        placeholder="商品"
        show-search
        allowClear
        v-model="queryParam.goodsId"
        :options="goodsOptions"
        :filterOption="filterOption"
      />
      <a-input
        style="width: 8%; margin-left: 8px;"
        placeholder="快递公司"
        v-model="queryParam.waybillCompany"
      />
      <a-input
        style="width: 10%; margin-left: 8px;"
        placeholder="快递单号"
        v-model="queryParam.waybillNo"
      />
      <a-input
        style="width: 8%; margin-left: 8px;"
        placeholder="原快递公司"
        v-model="queryParam.oldWaybillCompany"
      />
      <a-input
        style="width: 10%; margin-left: 8px;"
        placeholder="原快递单号"
        v-model="queryParam.oldWaybillNo"
      />
      <a-range-picker
        :placeholder="['创建时间开始', '创建时间结束']"
        style="width: 15%; margin-left: 8px;"
        @change="onChangeDatePay"
      />
      <a-button
        style="width: 7%; margin-left: 8px;"
        class="editable-add-btn"
        @click="$refs.table.refresh(false)"
        >查询</a-button
      >
      <a-button
        style="width: 7%; margin-left: 8px;"
        class="editable-add-btn"
        @click="exportCurrent"
        >导出</a-button
      >
      <div style="color: red; margin-top: 10px">
        注： 标红且发货时间为1970-01-01指下单发货失败，需换地址或换仓库重新下单~
      </div>
      <s-table
        style="margin-top: 10px"
        ref="table"
        size="small"
        rowKey="id"
        :columns="columns"
        :data="dataSource"
        :scroll="{ x: 1400 }"
        :pageSizeOptions="['20', '100', '1000', '5000', '10000']"
        :rowClassName="rowClassNameFn"
      >
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <template slot="name" slot-scope="text">
          {{ sreReplace(text, "##", "（姓名已加密）") }}
        </template>
        <template slot="mobile" slot-scope="text">
          {{ sreReplace(text, "$$", "（手机号已加密）") }}
        </template>
        <template slot="goodsId" slot-scope="text">
          {{ goodsHex[text] || '-' }}
        </template>
        <template slot="waybillNo" slot-scope="text">
          <a @click="copyInfo(text)">{{ text }}</a>
        </template>
        <template slot="operation" slot-scope="text, record">
          <a @click="showOrder(record)">查看详情</a>
        </template>
      </s-table>
    </a-card>
    <a-modal
      :visible="preview.show"
      :footer="null"
      @cancel="() => (preview.show = false)"
    >
      <img style="width: 100%" :src="preview.image" />
    </a-modal>
    <a-modal
      :visible="order.show"
      :footer="null"
      @cancel="() => (order.show = false)"
    >
      <p>系统订单号： {{ order.content.no }}</p>
      <!--      <p>原快递公司： {{ order.record.oldWaybillCompany }}</p>-->
      <!--      <p>原快递单号： {{ order.record.oldWaybillNo }}</p>-->
      <p>快递公司： {{ order.record.waybillCompany }}</p>
      <p>快递单号： {{ order.record.waybillNo }}</p>
      <p>订单号： {{ order.record.orderNo }}</p>
      <p>收货人： {{ order.record.name }}</p>
      <p>联系电话： {{ order.record.mobile }}</p>
      <p>
        收货地址：
        {{
          order.record.province +
            order.record.city +
            order.record.county +
            order.record.address
        }}
      </p>
    </a-modal>
  </page-view>
</template>
<script>
import { STable } from "@/components";
import { PageView } from "@/layouts";
import {
  orderDetailList,
  orderDetailUpdate,
  filePreUpload,
  uploadOSS,
  orderGet,
  goodsListAll
} from "@api";
import { formatDate, uuid, sreReplace } from "@/utils/util";
import { exportXLSX } from "@/utils/excel/export";
import { renderStatus } from '@/utils/app'
import dayjs from "dayjs";
import moment from "moment";
import "moment/locale/zh-cn";
moment.locale("zh-cn");

export default {
  data() {
    return {
      order: { show: false, content: {}, record: {} },
      preview: { image: "", show: false },
      solve: { show: false },
      queryParam: {},
      goodsHex: {}, // 商品映射
      goodsOptions: [], // 商品选项
      columns: [
        {
          title: "#",
          scopedSlots: { customRender: "serial" },
          fixed: "left",
          width: 60
        },
        { title: "订单id", dataIndex: "id" },
        { title: "订单号", dataIndex: "orderNo" },
        {
          title: "姓名",
          dataIndex: "name",
          scopedSlots: { customRender: "name" }
        },
        {
          title: "手机号",
          dataIndex: "mobile",
          scopedSlots: { customRender: "mobile" }
        },
        {
          title: "状态",
          dataIndex: "status",
          customRender: renderStatus
        },
        {
          title: "商品",
          dataIndex: "goodsId",
          scopedSlots: { customRender: "goodsId" }
        },
        // { title: '原快递公司', dataIndex: 'oldWaybillCompany' },
        // { title: '原快递编号', dataIndex: 'oldWaybillNo' },
        { title: "快递公司", dataIndex: "waybillCompany" },
        {
          title: "快递单号",
          dataIndex: "waybillNo",
          scopedSlots: { customRender: "waybillNo" }
        },
        {
          title: "创建时间",
          dataIndex: "createTime",
          customRender: formatDate
        },
        {
          title: "发货时间",
          dataIndex: "deliveryTime",
          customRender: formatDate
        },
        {
          title: "操作",
          dataIndex: "comment",
          scopedSlots: { customRender: "operation" },
          fixed: "right",
          width: 120
        }
      ],
      dataSource: parameter => {
        return orderDetailList(
          Object.assign(parameter, this.queryParam),
          1
        ).then(result => {
          return result.data;
        });
      }
    };
  },
  created() {
    this.queryALlGoods()
  },
  components: {
    PageView,
    STable
  },
  methods: {
    sreReplace,
    moment,
    rowClassNameFn(record) {
      if (record.deliveryTime === 1) return "yc";
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    async queryALlGoods(){
      const res = await goodsListAll({pageSize: 10000})
      if (res.success) {
        this.goodsOptions = res.data.list.map(val => {
          this.goodsHex[val.id] = val.name
          return { label: val.name, value: val.id }
        })
      }
    },
    showOrder(record) {
      this.order.content = {};
      orderGet(1, record.orderId).then(result => {
        if (result.success) {
          this.order = {
            show: true,
            content: result.data,
            record: record
          };
        } else {
          this.$message.error(result.msg);
        }
      });
    },
    onChangeDatePay(date, dateString) {
      const time = dateString.includes("");
      if (time) {
        delete this.queryParam.createTimeStart;
        delete this.queryParam.createTimeEnd;
      } else {
        this.queryParam.createTimeStart = Date.parse(new Date(dateString[0]));
        this.queryParam.createTimeEnd = Date.parse(new Date(dateString[1]));
      }
      this.$refs.table.refresh(false);
    },
    onChangeDateProblem(date, dateString) {
      const time = dateString.includes("");
      if (time) {
        delete this.queryParam.problemTimeStart;
        delete this.queryParam.problemTimeEnd;
      } else {
        this.queryParam.problemTimeStart = Date.parse(new Date(dateString[0]));
        this.queryParam.problemTimeEnd = Date.parse(new Date(dateString[1]));
      }
      this.currentShortcutDate = null;
      this.$refs.table.refresh(false);
    },
    solveEdit(detail) {
      detail.show = true;
      this.preview.image = detail.solveFile;
      this.solve = JSON.parse(JSON.stringify(detail));
    },
    solveCancel() {
      this.solve = { show: false };
    },
    solveOk() {
      this.solve.solveFile = this.preview.image;
      orderDetailUpdate(this.solve, "solve").then(result => {
        if (result.success) {
          this.$message.success("反馈问题成功");
          this.solve = { show: false };
          this.$refs.table.refresh(false);
        } else {
          this.$message.error(result.msg);
        }
      });
    },
    handleShortcutDate(record) {
      this.currentShortcutDate = record.date;
      const today = new Date(new Date().toLocaleDateString()).getTime();
      let problemTimeEnd = today + 24 * 60 * 60 * 1000;
      const problemTimeStart = today - 24 * 60 * 60 * 1000 * record.date;
      if (record.date === 1) problemTimeEnd = today;
      this.queryParam.problemTimeStart = problemTimeStart;
      this.queryParam.problemTimeEnd = problemTimeEnd;
      this.$refs.table.refresh(false);
    },
    customRequest(fileData) {
      // TODO 1 上传到OSS
      filePreUpload()
        .then(response => {
          const data = response.data;
          const url = data.url;
          const prefix = data.key;
          delete data.url;
          delete data.key;
          const array = fileData.file.name.replace(" ", "").split("."); // 把文件名数组化
          let suffix = ""; // 后缀
          if (array.length > 1) {
            suffix = `.${array.pop().toLowerCase()}`;
          }
          const formData = new FormData();
          Object.keys(data).forEach(it => {
            formData.append(it, data[it]);
          });
          const key = `${prefix}/${uuid()}${suffix}`; // 图片访问路径
          formData.append("key", key);
          formData.append("file", fileData.file);
          uploadOSS(url, formData)
            .then(response => {
              // 上传成功，设置图片访问路径
              this.preview.image = "http://czkj-cache.oss-cn-shenzhen.aliyuncs.com/" + key;
              this.$message.success("上传成功.");
              return fileData.onSuccess(); // 上传成功了
            })
            .catch(error => {
              this.$message.error("上传异常." + error);
              return fileData.onError(); // 拒绝上传操作
            });
        })
        .catch(error => {
          this.$message.error("授权异常." + error);
          return fileData.onError(); // 拒绝上传操作
        });
    },
    exportCurrent() {
      const th = [
        "订单id",
        "订单号",
        "姓名",
        "手机号",
        "商品名",
        "地址",
        "物流公司",
        "物流单号",
        "店铺名",
        "创建时间",
        "发货时间"
      ];
      console.log(
        "[this.$refs.table.localDataSource] ---> ",
        this.$refs.table.localDataSource
      );
      const data = this.$refs.table.localDataSource.map(val => [
        val.id,
        val.orderNo,
        val.name,
        val.mobile,
        this.goodsHex[val.goodsId],
        val.address,
        val.waybillCompany,
        val.waybillNo,
        val.shopName,
        dayjs(val.createTime).format("YYYY-MM-DD HH:mm:ss"),
        dayjs(val.deliveryTime).format("YYYY-MM-DD HH:mm:ss")
      ]);
      data.unshift(th);
      exportXLSX(data, `发货列表_${dayjs().format("YYYY_MM_DD_HH_mm_ss")}`);
    }
  },
  computed: {
    title() {
      return this.$route.meta.title;
    }
  }
};
</script>

<style lang="less" scoped>
.top5 {
  margin-bottom: 8px;
}
.time-select {
  display: inline-block;
  padding: 0;
  margin-left: 8px;
  li {
    list-style: none;
    display: inline-block;
    margin-right: 8px;
    cursor: pointer;
  }
  li.active {
    color: #1890ff;
  }
}
/deep/ .ant-table-tbody .yc {
  td,
  a {
    color: red;
  }
}
</style>
